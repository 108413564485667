<template>
  <div class="flex">
    <font-awesome-icon :icon="['fas', 'language']" class="mt-2 mr-3" />
    <Select v-model="$i18n.locale" :options="languages" optionLabel="name" optionValue="code" class="w-full md:w-14rem" />
  </div>
</template>

<script>

  import Select from 'primevue/select';

  export default {
    components: {
      Select
    },

    computed: {
      languages(){
        let names = {
          en: "English",
          zh: "Chinese"
        }

        return this.$i18n.availableLocales.map((locale) => {
          let name = names[locale]
          return {
            name: name,
            code: locale
          }
        })
      }
    }
  }
</script>