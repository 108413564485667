<template>
  <div>
    <div class="bg-surface-0 dark:bg-surface-950 px-6 py-20 md:px-12 lg:px-20">
      <div class="text-surface-700 dark:text-surface-100 text-center">
        <div class="text-surface-900 dark:text-surface-0 font-bold text-5xl mb-2">
          <h1>Hǎiguī</h1>
        </div>
        <div class="text-surface-700 dark:text-surface-100 text-2xl mb-3">One wave, one dram</div>
      </div>
    </div>

    <div class="flex flex-column text-lg mx-4 md:mx-8">
      <p class="">
        {{ $t('introduction', { companyName: companyName, profitPercentage: profitPercentage }) }}
      </p>
      <p>
        {{ $t('announcement') }}
      </p>
      <p>
        {{ $t('release_availability') }}
      </p>
    </div>

    <div class="text-surface-700 flex flex-column">
      <div class="flex justify-content-center mb-4">
        <LanguageDropdown class="mt-4"></LanguageDropdown>
      </div>
      <div class="text-center">
        <p>
          {{ $t('responsible_drinking') }}
        </p>
        <p>
          <span class="mr-2">
            © {{ copyrightYear }} {{ companyName }}
          </span>
          <span> | </span> 
          <span class="ml-2 font-bold">
            {{ companyEmail }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import Button from "primevue/button"

import LanguageDropdown from '~/components/Shared/LanguageDropdown.vue';
export default {

  components: {
    Button,
    LanguageDropdown
  },

  computed: {
    copyrightYear() {
      let date = new Date()
      return date.getFullYear();
    },

    companyName(){
      return "Hǎiguī"
    },

    profitPercentage(){
      return "10%"
    },

    companyEmail(){
      return "hello@haigui.tw"
    }
  }

}
</script>